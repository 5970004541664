/**************************************/
/* APP GENERAL */
/**************************************/

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

html,
body {
  height: 100%;
  margin: 0px !important;
}

#root {
  height: 100%;
}

.page-container {
  height: 100%;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  /* min-height: 100vh; */
  height: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/**************************************/
/* DASHBOARD */
/**************************************/

/* .dash-example {
    width: 100vw;
    content: url('./images/dash.png');
} */

/**************************************/
/* LOGIN */
/**************************************/

.full-height {
  height: 100%;
}

.login-container {
  height: 60vh;
  width: 50vh;
  margin-top: 30vh;
  margin-left: 150px;
}

.right {
  float: right;
  margin-left: auto;
}

.left {
  float: left;
  margin-right: auto;
}

.container-banner {
  margin: auto;
  width: 410px;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}

.title-banner {
  font-size: 28px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.07;
  letter-spacing: normal;
  color: #ffffff;
}

.link-orange {
  cursor: pointer;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.67px;
  color: #ef7d00 !important;
}

.link-sm {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  color: #707070;
  cursor: pointer;
  text-decoration: underline !important;
}

.title-login {
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.43;
  letter-spacing: normal;
  color: #4a4a4a;
}

.button-primary {
  border-radius: 25.5px;
  background-color: #ef7d00;
  height: 51px;
  border: #ef7d00;
  min-width: 115px;

  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.67px;
  color: #ffffff;
}

.button-primary:hover {
  border-radius: 25.5px;
  background-color: #ff8400;
  height: 51px;
  border: #ff8400;
  min-width: 115px;

  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.67px;
  color: #ffffff;
}

.button-container-login {
  margin: 15px;
}
